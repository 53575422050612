import React from 'react'

const Twitter = () => (
  <svg
    width="29"
    height="22"
    viewBox="0 0 29 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 19.209C2.53664 20.8794 5.57874 21.8983 8.87825 21.992C19.7247 22.3 26.1231 13.6473 25.9959 5.7204C27.1614 4.96026 28.1823 3.99717 29 2.88938C27.938 3.30728 26.803 3.57807 25.6182 3.68014C26.8442 3.01967 27.8046 1.9425 28.2808 0.639573C27.1321 1.25261 25.8675 1.68552 24.53 1.90107C23.4973 0.776471 22.0002 0.0499524 20.3193 0.00251853C16.5486 -0.104358 13.6729 3.207 14.4171 6.75193C9.56882 6.37967 5.33256 4.01698 2.55596 0.529094C0.947969 3.01607 1.58306 6.34664 4.13404 8.09569C3.17672 8.03925 2.28236 7.75945 1.50827 7.31574C1.36555 9.9222 3.23095 12.4158 6.02375 13.039C5.1942 13.2318 4.29235 13.2546 3.38302 13.06C4.05676 15.3182 6.16523 17.0055 8.72057 17.1243C6.20823 18.9094 3.08885 19.6497 0 19.209"
      fill="currentColor"
    />
  </svg>
)

export default Twitter
