import en from '../translations/en.json'

export type Messages = Partial<typeof en>

export enum Language {
  en = 'en_GB',
}

const translations = (
  language: Language = process.env.GATSBY_LANGUAGE as Language,
) => {
  switch (language) {
    default:
      return en as Messages
  }
}

export default translations
