import React, { useState } from 'react'
import { ShopifyProduct, ShopifyProductData } from '../../types/pages/homepage'
import loadable from '@loadable/component'
import ProductVideo from '../product-video'
import { useImageSources } from '@superrb/gatsby-addons/hooks'

const BuyButton = loadable(() => import('../../components/buy-button'))

const Product = (
  { product: { product_shopify_id, product_title, product_image, product_video } }: { product: ShopifyProduct },
) => {
  const { src: posterImage } = useImageSources(product_image)

  return (
  <section className={'buy-button'}>
    <div className={'buy-button__inner'}>
      <div className={'buy-button__col buy-button__col--image'}>
        <ProductVideo
          videoUrl={product_video?.url}
          posterImage={posterImage}
        />
      </div>
      <div className={'buy-button__col buy-button__col--text'}>
        <h3 className={'buy-button__title'}>{product_title}</h3>
        <BuyButton id={product_shopify_id} />
      </div>
    </div>
  </section>
)
  }

const ProductSection = ({ products }: ShopifyProductData) => {
  const [playing, setPlaying] = useState(false)

  return (
    <section className={'section-buy-online'} id={'buy-online'}>
      {products &&
        products.map((product, index) => <Product product={product} key={index} />)}
    </section>
  )
}

export default ProductSection
