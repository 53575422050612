import React, { useContext, useEffect, useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import MenuToggle from './navigation/mobile-menu-toggle'
import { NavContext } from '@superrb/gatsby-addons/context'
import { useIsMobile, useLockBodyScroll } from '@superrb/gatsby-addons/hooks'
import LordsOfWaterLogo from './icons/lords-of-water-logo.svg'
import Footer from './footer'
import eye from './lottie/eye-2.json'
import AnimatedIcon from './animated-icon'

const Header = () => {
  const data = useStaticQuery(graphql`
    query MainHeaderQuery {
      header: prismicMainHeader {
        ...MainHeader
      }
    }
  `)

  /** @type {MainHeader} header */
  const header = data.header
  if (!header) {
    return null
  }

  const isMobile = useIsMobile()
  const { navOpen, closeNav } = useContext(NavContext)
  const [documentReady, setDocumentReady] = useState<boolean>(false)
  useLockBodyScroll(navOpen)

  useEffect(() => {
    setDocumentReady(true)
  }, [])

  return (
    <header
      className={`main-header ${
        isMobile && navOpen ? 'main-header--nav-open' : ''
      } ${documentReady ? '' : 'main-header--preload'}`}
    >
      <div className="main-header__inner">
        <Link to="/" className="main-header__logo">
          <AnimatedIcon
            className="main-header__logo-graphic"
            animationData={eye}
          />
          <div className="main-header__logo-main">
            <LordsOfWaterLogo />
          </div>
        </Link>

        {isMobile && (
          <MenuToggle
            className="main-header__toggle"
            label="Open Nav"
            closeLabel="Close Nav"
          />
        )}

        {!isMobile ? (
          <nav className="main-header__nav nav">
            <ul className="nav__list">
              <li className="nav__item">
                <Link
                  to={'#learn'}
                  className="nav__link"
                  onClick={() => closeNav()}
                >
                  <span data-text="Learn">Learn</span>
                </Link>
              </li>
              <li className="nav__item">
                <Link
                  to={'#buy-online'}
                  className="nav__link"
                  onClick={() => closeNav()}
                >
                  <span data-text="Buy Online">Buy Online</span>
                </Link>
              </li>
              <li className="nav__item">
                <Link
                  to={'#about-us'}
                  className="nav__link"
                  onClick={() => closeNav()}
                >
                  <span data-text="Our Story">Our Story</span>
                </Link>
              </li>
              <li className="nav__item">
                <Link
                  to={'#shop'}
                  className="nav__link"
                  onClick={() => closeNav()}
                >
                  <span data-text="Stockists">Stockists</span>
                </Link>
              </li>
              <li className="nav__item">
                <Link
                  to={'#social'}
                  className="nav__link"
                  onClick={() => closeNav()}
                >
                  <span data-text="Social">Social</span>
                </Link>
              </li>
            </ul>
          </nav>
        ) : (
          ''
        )}
        {/*
        {header?.data?.tagline && (
          <div className="main-header__tagline">{header?.data?.tagline}</div>
        )}
        */}
      </div>

      {isMobile ? (
        <nav className="main-header__nav nav" aria-hidden={!navOpen}>
          <ul className="nav__list">
            <li className="nav__item">
              <Link
                to={'#learn'}
                className="nav__link"
                onClick={() => closeNav()}
              >
                <span data-text="Learn">Learn</span>
              </Link>
            </li>
            <li className="nav__item">
              <Link
                to={'#about-us'}
                className="nav__link"
                onClick={() => closeNav()}
              >
                <span data-text="Our Story">Our Story</span>
              </Link>
            </li>
            <li className="nav__item">
              <Link
                to={'#buy-online'}
                className="nav__link"
                onClick={() => closeNav()}
              >
                <span data-text="Buy Online">Buy Online</span>
              </Link>
            </li>
            <li className="nav__item">
              <Link
                to={'#shop'}
                className="nav__link"
                onClick={() => closeNav()}
              >
                <span data-text="Stockists">Stockists</span>
              </Link>
            </li>
            <li className="nav__item">
              <Link
                to={'#social'}
                className="nav__link"
                onClick={() => closeNav()}
              >
                <span data-text="Social">Social</span>
              </Link>
            </li>
          </ul>
          <Footer />
        </nav>
      ) : (
        ''
      )}
    </header>
  )
}

export default Header
