import React, { MutableRefObject, useContext, useRef, useState } from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { graphql, useStaticQuery } from 'gatsby'
import { Image, Modal, RichText } from '@superrb/gatsby-addons/components'
import NewsletterPopup from '../types/newsletter-popup'
import { ImageLayout } from '@superrb/gatsby-addons/types'

interface Data {
  NAME: string
  EMAIL: string
}

const NewsletterForm = ({
  onSubmitted,
  disabled,
}: {
  onSubmitted: (data: Data) => void
  disabled: boolean
}) => {
  const nameInput =
    useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>
  const emailInput =
    useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>
  const [error, setError] = useState<string>()

  const submit = () => {
    if (!nameInput.current?.value) {
      setError('Please enter your name')
      return
    } else {
      setError('')
    }

    if (
      !(
        emailInput.current?.value &&
        emailInput.current?.value?.indexOf('@') > -1
      )
    ) {
      setError('Please enter a valid email address')
      return
    }

    onSubmitted({
      NAME: nameInput.current.value,
      EMAIL: emailInput.current.value,
    })
  }

  return (
    <div className="newsletter__form">
      {error && <p className="newsletter__form-error">{error}</p>}
      <input
        ref={nameInput}
        type="text"
        placeholder="Your name"
        disabled={disabled}
      />

      <div className="newsletter__form-row">
        <input
          ref={emailInput}
          type="email"
          placeholder="Your email"
          disabled={disabled}
        />
        <button onClick={submit} disabled={disabled}>
          Submit
        </button>
      </div>
    </div>
  )
}

const Newsletter = () => {
  const {
    popup: {
      data: { title, text, success_message, image },
    },
  }: { popup: NewsletterPopup } = useStaticQuery(graphql`
    query NewsletterQuery {
      popup: prismicNewsletterPopup {
        ...NewsletterPopup
      }
    }
  `)

  return (
    <Modal
      name="newsletter"
      openAfter={10000}
      dismissable={true}
      className="newsletter"
    >
      <div className="newsletter__content">
        {/* <h2 className="newsletter__title">{title}</h2> */}

        <RichText className="newsletter__text" field={text.richText} />

        <MailchimpSubscribe
          url={process.env.GATSBY_MAILCHIMP_SIGNUP_URL}
          render={({ subscribe, status, message }) => (
            <>
              {status === 'success' ? (
                <RichText
                  className="newsletter__success"
                  field={success_message.richText}
                />
              ) : (
                <>
                  <NewsletterForm
                    onSubmitted={(formData: Data) => subscribe(formData)}
                    disabled={status === 'sending'}
                  />
                  {status === 'error' && (
                    <p className="newsletter__error">{message}</p>
                  )}
                </>
              )}
            </>
          )}
        />
      </div>

      <Image
        image={image}
        className="newsletter__image"
        layout={ImageLayout.contain}
      />
    </Modal>
  )
}

export default Newsletter
