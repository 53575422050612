import React from 'react'
import LordsOfWaterLogo from '../icons/lords-of-water-logo.svg'
import time from '../lottie/time-1.json'
import AnimatedIcon from '../animated-icon'

const LogoSection = () => {
  return (
    <section className="logo-section" aria-label="The Lords of Water">
      <div className="logo-section__inner">
        <AnimatedIcon className="logo-section__graphic" animationData={time} />
        <div className="logo-section__logo">
          <LordsOfWaterLogo />
        </div>
      </div>
    </section>
  )
}

export default LogoSection
