import { Image } from '@superrb/gatsby-addons/components'
import { useIsInViewport } from '@superrb/gatsby-addons/hooks'
import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { MapSectionData } from '../../types/pages/homepage'

const MapSection = ({
  map_title: title,
  map_stockists: stockists,
  map_link: link,
  map_image_1: image_1,
  map_image_2: image_2,
}: MapSectionData) => {
  const { isInViewport, setRef } = useIsInViewport(false)
  const [playing, setPlaying] = useState<boolean>(true)

  return (
    <section className="map-section" aria-label={title} id="shop">
      <div className="map-section__inner">
        <div className="map-section__column">
          {title && <h3 className="map-section__title">{title}</h3>}

          {image_1 &&
            (() => {
              const image = (
                <div className="map-section__image-wrapper">
                  <ReactPlayer
                    url={`https://superrb-video.fra1.cdn.digitaloceanspaces.com/lords-of-water/map.mp4`}
                    autoPlay={true}
                    loop={true}
                    muted={true}
                    playing={true}
                    className="map-section__video"
                    onPlay={() => setPlaying(true)}
                    height="100%"
                    width="100%"
                  />
                  <Image
                    className="map-section__image map-section__image--one"
                    aria-hidden={playing}
                    image={image_1}
                  />
                </div>
              )

              if (link?.url) {
                return (
                  <a href={link?.url} target="_blank" rel="nofollow noopener">
                    {image}
                  </a>
                )
              }

              return image
            })()}

          {stockists && (
            <div className="map-section__stockists">
              <ul
                className="map-section__stockists-inner"
                style={{
                  animationPlayState: isInViewport ? 'running' : 'paused',
                }}
                ref={setRef}
              >
                {Array.from(Array(2)).map((item, i) => (
                  <>
                    {stockists.map(({ name: name }, index) => (
                      <li
                        className="map-section__stockists-item"
                        key={index}
                        aria-label={name}
                        aria-hidden={i !== 0}
                      >
                        {name}
                      </li>
                    ))}
                  </>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div className="map-section__column">
          {image_2 && <Image className="map-section__image" image={image_2} />}
        </div>
      </div>
    </section>
  )
}

export default MapSection
