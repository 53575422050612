import React from 'react'
import SocialIcons from './social-icons'
import Heart from './icons/heart.svg'
import Arrow from './icons/arrow.svg'
import { useIsMobile, useModal } from '@superrb/gatsby-addons/hooks'

const Footer = () => {
  const isMobile = useIsMobile(true)
  const { openModal: openNewsletter } = useModal('newsletter')

  return (
    <footer className="footer">
      <div className="footer__inner">
        <div className="footer__container">
          <div className="footer__attribution">
            {!isMobile && <Heart />}
            <div className="footer__attribution-inner">
              &copy; Copyright {new Date().getFullYear()} Lords of Water. All
              Rights Reserved.{' '}
              <a href="mailto:worship@thelordsofwater.com">Make Contact</a>.
              Site by{' '}
              <a
                href="https://www.superrb.com"
                rel="nofollow noopener"
                target="_blank"
                title="Site by Superrb"
              >
                Superrb
              </a>
              .
            </div>
          </div>

          <div className="footer__attribution footer__attribution--newsletter">
            <a onClick={openNewsletter}>Newsletter</a>
          </div>

          <SocialIcons className="footer__social-icons" />
        </div>

        <a href="#top" className="footer__back-to-top back-to-top">
          <div className="back-to-top__icon">
            <Arrow />
          </div>
          <div className="back-to-top__icon">
            <Arrow />
          </div>
          <span className="screenreader-text">Back to top</span>
        </a>
      </div>
    </footer>
  )
}

export default Footer
