import React, { StrictMode } from 'react'
import { Link } from 'gatsby'
import { PrismicProvider } from '@prismicio/react'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import { Layout } from './src/components/layout'
import { repositoryConfigs } from './src/utils/prismicPreviews'
import { NavContextProvider } from '@superrb/gatsby-addons/context'

import './src/stylesheets/style.sass'
import { StateInspector } from 'reinspect'
import { ContextWrapper } from '@superrb/gatsby-addons/components'

export const wrapRootElement = ({ element }) => (
  <StrictMode>
    <StateInspector name="App">
      <PrismicProvider
        internalLinkComponent={({ href, ...props }) => (
          <Link to={href} {...props} />
        )}
      >
        <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
          <ContextWrapper>
            <Layout>{element}</Layout>
          </ContextWrapper>
        </PrismicPreviewProvider>
      </PrismicProvider>
    </StateInspector>
  </StrictMode>
)

export const onRouteUpdate = ({ location: { pathname }, prevLocation }) => {
  if (pathname === '/') {
    pathname = 'home'
  }

  document.body.setAttribute(
    'class',
    `page ${[
      ...pathname
        .split('/')
        .filter((x) => !!x)
        .map((slug) => `page--${slug}`),
    ].join(' ')}`,
  )
}
