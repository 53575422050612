import React from 'react'

const WaterText = () => (
  <svg
    width="268"
    height="88"
    viewBox="0 0 268 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4_30)">
      <path
        d="M231.803 19.7724H232.593C239 19.7724 242.219 23.0654 242.219 29.6363C242.219 32.8696 241.37 35.5218 239.671 37.6228C237.972 39.7088 235.841 40.7518 233.293 40.7518H231.803V19.7724ZM251.518 51.4053C259.983 47.3376 264.215 39.9919 264.215 29.3532C264.215 19.3999 261.056 11.7264 254.752 6.33254C249.79 2.11581 242.309 0 232.324 0H209.628V88H231.684V57.2313L231.52 51.4202C231.907 52.6421 232.235 53.6851 232.503 54.5493C232.771 55.4284 232.95 56.1287 233.069 56.6651L244.023 88.0149H268L251.518 51.4202V51.4053ZM211.059 88V66.8568H192.029V53.402H209.092V33.5103H192.029V20.6962H211.059V0H169.869V88H211.059ZM162.314 21.0836H175.026V0H127.935V21.0836H140.155V88H162.314V21.0836ZM107.042 57.9614L109.277 44.1639L110.812 33.5103C110.842 33.3166 110.917 32.7653 111.021 31.8564C111.125 30.9475 111.304 29.3085 111.557 26.9543C111.692 25.7772 111.826 24.6895 111.96 23.7061C112.079 22.7227 112.183 21.8286 112.258 21.024L112.943 29.3383C113.122 31.0518 113.256 32.4673 113.361 33.5848C113.465 34.7023 113.554 35.5963 113.629 36.237C114.299 42.0183 114.672 45.3261 114.746 46.1605C114.851 47.1439 114.925 47.6654 114.955 47.6952L116.386 57.9614H107.027H107.042ZM126.191 0H99.0843L79.3688 88H102.273L104.077 76.8249H119.008L120.663 88H143.567L126.191 0ZM96.1486 0H74.5107L70.4722 27.297C69.9059 31.0965 69.3844 35.3281 68.9373 40.0068C68.4753 44.6854 68.0432 50.0345 67.6557 56.084C66.3443 42.6292 64.7945 30.9922 63.0361 21.1432L59.3105 0H40.2358L36.093 25.1365C34.9306 32.3928 33.962 40.0515 33.2169 48.1124L32.5314 55.0112L31.7863 46.98C31.2498 41.467 30.2961 33.8977 28.9102 24.2425L25.289 0H3.38281L21.6677 88H43.246L47.4484 56.084C47.8061 53.4318 48.1041 50.8987 48.3575 48.4998C48.6108 46.1009 48.8194 43.8212 48.9983 41.6607C49.3559 37.4142 49.6241 34.4937 49.7881 32.9441C49.8626 33.704 49.9818 34.8066 50.1606 36.2519C50.3394 37.6973 50.5481 39.5002 50.8014 41.6607C51.2932 46.0264 52.0383 51.1967 53.0367 57.1568L58.1333 87.9851H79.5029L96.1337 0H96.1486Z"
        fill="#E30613"
      />
      <path
        d="M32.0098 34.0467C29.2529 36.6841 26.0191 39.5002 23.3665 41.3925C25.6615 44.8791 28.6866 47.7697 32.6208 49.5875C31.4137 50.5262 29.685 52.4782 28.8505 53.849C24.4693 51.4054 21.4144 47.7697 19.1194 43.3295V50.3027C19.1194 52.9103 18.583 54.2513 17.0033 55.0708C15.3939 55.8903 12.9947 56.0691 9.96952 56.0691C9.77579 54.6238 8.82205 52.2994 8.04714 50.9584C10.1334 51.0478 12.2495 51.0477 12.9947 51.0477C13.6652 51.0477 13.9037 50.8242 13.9037 50.258V26.2242H19.1194V31.6478C19.6857 33.6891 20.401 35.6708 21.2355 37.5483C23.456 35.5963 25.8105 33.1527 27.3603 31.022L32.0098 34.0318V34.0467ZM13.7398 34.7023C12.2943 43.4636 8.82206 50.1388 4.08318 53.5361C3.24866 52.4633 1.22197 50.6156 0.0148926 49.9302C3.68082 47.5462 6.3483 43.4636 7.7193 38.3529H1.17726V33.7338H9.49265L10.3719 33.5848L13.7398 34.7172V34.7023Z"
        fill="white"
      />
      <path
        d="M48.9386 30.5303C51.9637 30.3813 55.1677 30.128 57.9842 29.6512L57.9544 34.0467C54.8249 34.4192 50.6821 34.7023 47.1205 34.8364C46.6884 35.6559 46.2413 36.4307 45.8091 37.0565C46.4797 36.8628 48.1935 36.7436 49.1323 36.7436C51.1143 36.7436 52.7386 37.5035 53.6179 38.9339C54.9591 38.3379 56.0767 37.9356 57.254 37.429C58.7293 36.833 60.1152 36.237 61.5607 35.5814L63.5427 39.9769C62.3654 40.2898 60.2493 41.0051 58.9677 41.4819C57.6861 41.9587 56.032 42.5547 54.2587 43.2699C54.2885 44.9983 54.363 47.0992 54.3928 48.5147H49.5496C49.6241 47.6952 49.6837 46.533 49.7135 45.341C47.5974 46.5032 46.6884 47.5313 46.6884 48.7978C46.6884 50.109 47.7315 50.6454 50.8908 50.6454C53.8861 50.6454 57.716 50.2729 60.6815 49.6769L60.5176 54.5641C58.4611 54.8472 54.1245 55.1303 50.7865 55.1303C45.2727 55.1303 41.2938 53.7744 41.2938 49.6024C41.2938 45.4304 45.3025 42.8825 49.028 41.0647C48.5213 40.558 47.8209 40.409 47.1801 40.409C45.4664 40.409 43.3801 41.4372 42.2326 42.51C41.2193 43.4785 40.2506 44.6109 39.1032 46.0562L34.7667 43.0166C37.9259 40.3792 40.3252 37.8015 41.9644 34.9258C40.5934 34.9258 38.1345 34.8662 36.3463 34.7023V30.3068C38.0302 30.5601 40.5934 30.6793 42.4115 30.6793H43.7228C44.0209 29.5171 44.2295 28.3251 44.2891 27.2523L49.6688 27.6546C49.5347 28.34 49.2962 29.3234 48.9237 30.5154"
        fill="white"
      />
      <path
        d="M78.9664 45.8476C79.7413 47.3823 80.5758 49.2746 81.1123 50.8094C85.6277 47.0098 86.8645 41.5266 86.8645 38.3529V35.7156H86.5665C85.732 37.6675 84.7186 39.3214 83.5414 40.6773C82.9751 40.2005 81.7829 39.4257 80.7398 38.8297V45.8625H78.9515L78.9664 45.8476ZM75.7028 48.7382C75.4346 48.0826 75.1664 47.4568 74.8981 46.8906L78.0574 45.8625H72.946V49.4087L75.7028 48.7531V48.7382ZM72.946 42.7782H76.4778V41.7799H72.946V42.7782ZM72.946 38.8297H76.4778V37.891H72.946V38.8297ZM72.8118 32.6759C72.171 33.3613 71.4706 34.0914 70.6957 34.8066H72.8118V32.6759ZM91.6928 38.3529C91.6928 41.8097 93.2129 48.3061 98.4286 52.0312C97.5792 53.0295 96.1784 54.9516 95.4333 56.1138C91.827 53.6106 89.7854 48.6488 89.3085 45.788C88.7422 48.5892 86.2089 53.4765 81.9468 56.084C81.47 55.2347 80.5013 53.8192 79.6966 52.8507L77.3719 53.7C77.2676 53.2977 77.1633 52.8209 76.9993 52.2845C73.7656 53.253 70.502 54.2364 67.7749 55.0112L66.2698 50.8392C66.9702 50.72 67.7451 50.5561 68.6243 50.3624V36.4904L68.52 36.55C67.9537 35.5815 66.6274 34.002 65.7631 33.3464C68.8628 31.3051 70.9193 28.6082 72.022 26.2242H76.5374C78.4597 27.893 80.9782 30.2174 82.3641 31.8415L79.0707 34.7917C78.1617 33.5401 76.448 31.4988 74.8981 30.0237C74.3318 30.8432 73.6464 31.7223 72.8714 32.6014H77.1484V34.8066H80.7845V36.6543C82.7068 33.9573 83.8543 30.1876 84.4206 26.2093L89.1595 26.8649C88.8912 28.5039 88.5187 30.0982 88.0865 31.6031H94.3454L95.0905 31.4541L98.3839 32.1395C97.907 35.3729 97.0427 38.8595 96.0294 41.1839L92.1548 40.0515C92.5572 38.8893 92.8999 37.3248 93.1682 35.7156H91.7226V38.3529H91.6928Z"
        fill="white"
      />
      <path
        d="M131.496 36.2371L127.831 39.7535C125.878 37.593 122.645 34.613 120.663 33.2869L124.09 30.1131C126.281 31.3349 129.976 34.5683 131.496 36.2371ZM109.084 42.6739C109.084 41.6011 108.547 41.0051 107.742 41.0051C106.565 41.0051 105.656 42.0034 105.656 43.553C105.656 44.7152 106.431 45.4006 107.236 45.4006C108.547 45.4006 109.084 44.2682 109.084 42.6739M112.69 31.0369C114.538 30.8134 116.49 30.4707 118.442 29.9641V34.5236C116.52 34.9259 114.538 35.1792 112.69 35.3729V39.1128C113.39 39.9919 113.733 41.1541 113.733 42.4951C113.733 44.5662 113.197 46.4436 112.019 47.7996V48.6489C112.019 49.7813 112.317 50.4667 115.79 50.4667C117.369 50.4667 119.321 50.273 120.633 49.9005C122.376 49.3939 122.928 48.2764 122.928 46.9204C122.928 45.6986 122.898 44.447 122.66 42.8676L128.307 44.4321C128.307 46.2797 128.337 46.98 128.233 48.2913C128.024 51.0478 127.264 53.0593 122.913 54.3407C121.363 54.8473 117.727 55.0708 115.402 55.0708C106.923 55.0708 106.953 52.2845 106.953 50.1836V49.9601H106.714C103.957 49.9601 101.394 48.1721 101.394 43.9702C101.394 40.0515 104.047 37.3844 107.757 37.2503V35.6411H107.325C105.91 35.6411 104.121 35.5815 101.767 35.3878L101.469 30.9624C103.257 31.1859 105.448 31.3647 106.953 31.3647H107.757C107.757 29.3234 107.623 28.3549 107.355 27.3864H113.048C112.884 28.2953 112.705 29.2638 112.675 31.022"
        fill="white"
      />
      <path
        d="M166.129 42.7633L160.913 44.9983C160.109 41.3627 157.843 35.6113 155.429 32.7207L160.347 30.8134C162.403 33.6445 165.16 39.7237 166.129 42.7633M148.023 31.1263C147.814 31.9756 147.621 32.8548 147.412 33.6743C148.321 33.6147 149.096 33.5849 149.707 33.5849C153.581 33.5849 156.13 35.2835 156.13 39.9919C156.13 43.6573 155.698 48.8128 154.252 51.5693C153.105 53.8341 151.123 54.6089 148.395 54.6089C147.084 54.6089 145.504 54.4152 144.282 54.1619L143.403 49.1704C144.64 49.4833 146.667 49.826 147.501 49.826C148.574 49.826 149.349 49.4833 149.796 48.634C150.631 47.0694 151.033 43.3891 151.033 40.4687C151.033 38.1741 150.02 37.891 147.933 37.891C147.531 37.891 146.995 37.9208 146.324 37.9804C144.983 42.8676 142.45 50.4667 139.588 54.7728L134.268 52.7911C137.472 48.7681 139.648 42.808 140.9 38.5168C140.02 38.636 139.29 38.7403 138.813 38.7999C137.74 38.9489 135.788 39.2022 134.671 39.3959L134.194 34.2852C135.505 34.3746 136.787 34.315 138.128 34.2852C139.097 34.2554 140.482 34.166 142.002 34.0617C142.479 31.618 142.703 29.4426 142.643 27.5056L148.708 28.1016C148.47 29.0403 148.202 30.2323 148.008 31.1114"
        fill="white"
      />
      <path
        d="M175.085 39.6492C174.817 40.126 174.519 40.5879 174.206 41.0647C175.279 41.6011 176.501 42.2567 177.544 42.9123C177.976 41.8842 178.393 40.8114 178.692 39.6492H175.085V39.6492ZM193.4 51.599C194.413 51.599 194.607 50.7497 194.786 47.0247C195.859 47.7697 197.885 48.5892 199.197 48.8723C198.72 53.9831 197.483 55.7711 193.877 55.7711H190.047C185.874 55.7711 184.772 54.3556 184.772 49.811V32.7057H177.708L178.274 32.8249C177.976 33.704 177.604 34.5831 177.231 35.4622H180.331L181.165 35.3281L184.325 36.3264C182.611 46.3691 178.066 52.8358 171.568 55.9052C170.868 54.8622 169.109 53.0146 168.036 52.2993C170.972 51.0775 173.386 49.2597 175.279 46.8459C174.206 46.0562 172.82 45.1771 171.583 44.4917C171.151 44.9983 170.674 45.4304 170.167 45.8774C169.288 44.9685 167.604 43.6126 166.531 42.9272C169.557 40.4836 171.851 36.2668 173.029 32.6908H167.813V28.2953H198.139V32.6908H189.719V39.8429C191.537 38.6211 193.281 37.2651 194.696 36.1029L198.258 40.0515C195.531 41.7501 192.536 43.3146 189.704 44.6705V49.7812C189.704 51.3457 189.838 51.5692 190.852 51.5692H193.415L193.4 51.599Z"
        fill="white"
      />
      <path
        d="M221.833 50.0792C222.876 50.0792 223.756 49.3491 224.396 48.2614C223.651 47.9783 222.876 47.7846 222.072 47.7846C221.058 47.7846 220.388 48.2912 220.388 48.9468C220.388 49.6769 220.894 50.0792 221.833 50.0792ZM213.056 42.6441C213.354 42.0183 213.667 41.318 213.89 40.6624C214.322 39.4108 214.74 37.9953 215.038 36.5797C213.458 37.1162 212.147 37.9357 211.029 38.8744C211.268 39.4108 211.506 39.8727 211.73 40.3197C212.206 41.2286 212.609 41.9885 213.041 42.6441M207.423 48.8276C208.093 48.8276 209.032 48.1422 210.046 47.0694C209.539 46.3542 209.002 45.5049 208.436 44.4321C208.168 43.9702 207.9 43.4338 207.631 42.8974C206.857 44.2533 206.29 45.8178 206.29 47.0694C206.29 47.9187 206.618 48.8276 207.438 48.8276M220.939 30.9028C220.909 31.2157 220.865 31.5286 220.835 31.8713C226.721 32.2736 230.894 36.2072 230.894 42.0034C230.894 43.7318 230.73 45.3857 230.357 46.9353C231.669 47.8442 232.786 48.8872 233.651 49.8855L230.625 53.4616C230.059 52.806 229.314 51.9865 228.509 51.1669C227.094 53.0742 225.007 54.3109 222.087 54.3109C218.421 54.3109 215.962 52.6123 215.962 49.4534C215.962 46.98 218.257 44.1788 221.878 44.1788C223.189 44.1788 224.441 44.3725 225.618 44.6854C225.752 43.8659 225.827 43.0315 225.827 42.1822C225.827 38.0698 223.472 36.1625 220.209 35.939C219.806 38.0102 219.3 40.0813 218.689 41.8097C216.766 47.4568 211.893 53.8341 207.11 53.8341C204.144 53.8341 201.596 51.8822 201.596 47.9634C201.596 44.2533 203.012 41.1839 204.755 39.0234C205.024 38.6807 205.337 38.3678 205.635 38.0549C205.366 37.3993 205.128 36.7735 204.889 36.1774C204.487 35.045 204.353 34.3894 203.414 32.2289L208.198 30.3515C208.496 31.8266 208.943 33.3911 209.45 34.8662C211.238 33.6742 213.354 32.7653 215.813 32.2587C215.872 31.7223 215.917 31.1859 215.917 30.7538C215.947 29.2191 215.917 28.7721 215.813 27.8036L221.401 27.9228C221.163 29.1744 221.028 30.3962 220.969 30.9326"
        fill="white"
      />
      <path
        d="M266.778 42.7633L261.562 44.9983C260.757 41.3627 258.492 35.6113 256.078 32.7207L260.996 30.8134C263.052 33.6445 265.809 39.7237 266.778 42.7633ZM248.672 31.1263C248.463 31.9756 248.269 32.8548 248.061 33.6743C248.97 33.6147 249.745 33.5849 250.356 33.5849C254.23 33.5849 256.778 35.2835 256.778 39.9919C256.778 43.6573 256.346 48.8128 254.901 51.5693C253.753 53.8341 251.771 54.6089 249.044 54.6089C247.733 54.6089 246.153 54.4152 244.931 54.1619L244.052 49.1704C245.304 49.4833 247.316 49.826 248.165 49.826C249.238 49.826 250.013 49.4833 250.46 48.634C251.294 47.0694 251.697 43.3891 251.697 40.4687C251.697 38.1741 250.683 37.891 248.597 37.891C248.195 37.891 247.658 37.9208 246.988 37.9804C245.647 42.8676 243.113 50.4667 240.252 54.7728L234.932 52.7911C238.136 48.7681 240.312 42.808 241.563 38.5168C240.684 38.636 239.954 38.7403 239.477 38.7999C238.404 38.9489 236.452 39.2022 235.334 39.3959L234.857 34.2852C236.169 34.3746 237.45 34.315 238.792 34.2852C239.76 34.2554 241.146 34.166 242.666 34.0617C243.143 31.618 243.367 29.4426 243.307 27.5056L249.372 28.1016C249.134 29.0403 248.865 30.2323 248.672 31.1114"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4_30">
        <rect width="268" height="88" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default WaterText
