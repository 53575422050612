import React from 'react'
import { VideoSectionData } from '../../types/pages/homepage'
import ReactPlayer from 'react-player'
import Peace from '../icons/peace.svg'
import Heart from '../icons/heart.svg'
import { useIsInViewport } from '@superrb/gatsby-addons/hooks'
import world from '../lottie/world.json'
import AnimatedIcon from '../animated-icon'

const VideoSection = ({
  video_title: title,
  video_url: url,
}: VideoSectionData) => {
  const { isInViewport, setRef } = useIsInViewport(false)

  return (
    <section
      className="video-section"
      id="learn"
      aria-label={title}
      ref={setRef}
    >
      <div className="video-section__inner">
        {url?.url && (
          <div className="video-section__video">
            <ReactPlayer
              url={url.url}
              autoPlay={true}
              muted={true}
              playing={isInViewport}
              controls={true}
              height="100%"
              loop={true}
              width="100%"
            />
          </div>
        )}

        {title && (
          <h3 className="video-section__title">
            <div className="video-section__title-icon">
              <Heart />
              <AnimatedIcon animationData={world} />
            </div>
            <span className="video-section__title-inner">{title}</span>
            <div className="video-section__title-icon">
              <Peace />
            </div>
          </h3>
        )}
      </div>
    </section>
  )
}

export default VideoSection
