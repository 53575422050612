import React, { useState } from 'react'
import ReactPlayer from "react-player"
import { useIsMobile } from '@superrb/gatsby-addons/hooks'

const ProductVideo = (props) => {
    const [playing, setPlaying] = useState(false)
    const isMobile = useIsMobile()


    return (
        <div
            className={'buy-button__product-video'}
            onMouseEnter={() => {
                if(!isMobile) {
                    setPlaying(true)
                }
            }}
            onMouseLeave={() => {
                if(!isMobile) {
                    setPlaying(false)
                }
            }}
        >

            <ReactPlayer
                url={props.videoUrl}
                className="react-player"
                autoPlay={false}
                muted={true}
                controls={false}
                height="100%"
                loop={true}
                width="100%"
                playsinline={true}
                playing={isMobile ? true : playing}
                config={{
                    file: {
                        attributes: {
                            poster: props.posterImage
                        }
                    }
                }}
            />
        </div>
    )
}

export default ProductVideo
