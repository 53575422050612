import React from 'react'
import { InstagramSectionData } from '../../types/pages/homepage'
import BarbedWire from '../icons/barbed-wire.svg'
import GlobeMesh from '../icons/globe-mesh.svg'
import Peace from '../icons/peace.svg'
import bird from '../lottie/bird.json'
import AnimatedIcon from '../animated-icon'
import { Image } from '@superrb/gatsby-addons/components'

const InstagramSection = ({
  instagram_title: title,
  instagram_handle: handle,
  instagram_images: images,
}: InstagramSectionData) => {
  return (
    <section className="instagram-section" aria-label={title} id="social">
      <div className="instagram-section__inner">
        <div className="instagram-section__title-container">
          {title && <h3 className="instagram-section__title">{title}</h3>}

          <div className="instagram-section__handle">
            <BarbedWire />

            <a
              href={'https://www.instagram.com/' + handle}
              className="instagram-section__handle-inner"
              target="_blank"
            >
              @{handle}
            </a>
          </div>

          <div className="instagram-section__icons">
            <Peace />
            <GlobeMesh />
            <AnimatedIcon animationData={bird} />
          </div>
        </div>

        {images && (
          <ul className="instagram-section__list">
            {images.map(({ image, link_url }, index) => (
              <li className="instagram-section__list-item" key={index}>
                {link_url?.url && image ? (
                  <a
                    href={link_url?.url}
                    target="_blank"
                    rel="noopener"
                    className="instagram-section__link"
                  >
                    <Image className="instagram-section__image" image={image} />
                  </a>
                ) : (
                  <Image className="instagram-section__image" image={image} />
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </section>
  )
}

export default InstagramSection
