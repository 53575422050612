import React from 'react'

const Peace = () => (
  <svg
    width="44"
    height="91"
    viewBox="0 0 44 91"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.717873 69.1161C1.16364 69.1161 1.64993 69.2179 2.05517 69.0957C3.29116 68.7087 4.04085 69.2179 4.72976 70.1753C5.58077 71.3568 6.71545 72.1716 8.17432 72.3346C9.7345 72.5179 10.9907 71.9679 11.7607 70.5624C12.0039 70.1142 12.247 70.0327 12.713 70.1346C14.1922 70.4198 15.1647 71.2753 15.6713 72.6809C16.3805 74.6364 16.9478 75.0438 19.0145 74.9216C19.9871 74.8605 20.9394 74.7179 21.912 74.6975C23.614 74.6568 24.6474 75.4716 24.8703 77.1419C25.0324 78.4049 24.9918 79.7086 24.8703 80.9715C24.7082 82.4789 24.3637 83.9659 24.0598 85.4326C23.7761 86.8178 22.9454 87.8159 21.5676 88.0807C19.9061 88.4067 18.204 88.6918 16.5223 88.7122C14.719 88.7122 13.2601 87.7141 12.1254 86.3696C11.1731 85.2289 10.3626 83.9863 9.45083 82.8049C6.99912 79.6475 4.48662 76.5308 2.05517 73.3735C1.46757 72.6198 0.961018 71.7642 0.5963 70.8883C0.373417 70.3587 0.474728 69.7068 0.434204 69.1161C0.535515 69.1161 0.616562 69.1161 0.717873 69.1364V69.1161ZM9.26847 77.7123C10.5247 82.6215 15.7929 86.9807 20.5747 85.5141C15.266 84.9437 12.1862 81.44 9.26847 77.7123Z"
      fill="#F9F9F9"
    />
    <path
      d="M6.45204 60.3569C5.90497 61.4365 5.47946 62.2717 5.05396 63.1272C5.13501 63.1883 5.21605 63.2698 5.27684 63.3309C5.92523 62.6587 6.6344 62.0476 7.222 61.3347C8.3972 59.8884 8.5593 58.0347 9.00506 56.3032C9.24821 55.3662 9.43057 54.4088 9.77502 53.4921C10.1803 52.3921 11.9431 51.7199 12.9359 52.2903C13.2196 52.4533 13.3411 52.881 13.6046 53.044C14.4353 53.5329 15.2863 54.0014 16.1576 54.3884C16.887 54.7144 17.272 55.2032 17.353 55.9977C17.4949 57.4847 17.8799 57.8717 19.6224 58.1365C19.0956 58.6662 18.7714 59.2569 18.2851 59.4606C17.0086 59.9902 15.6713 60.3365 14.3543 60.805C13.564 61.0902 12.7333 61.355 12.0039 61.7828C10.2816 62.8217 9.61293 64.7365 10.1803 66.692C10.3424 67.2624 10.5652 67.8328 10.7273 68.4031C11.1934 70.0735 9.99791 71.479 8.31615 71.0716C7.48541 70.8679 6.73571 70.2772 6.00628 69.7476C5.58077 69.442 5.31736 68.9327 4.93238 68.5253C4.284 67.8531 3.51404 67.5476 2.54146 67.7513C1.04207 68.0568 0.636823 67.6902 0.677347 66.142C0.717871 64.5328 1.32574 63.2495 2.72382 62.4143C3.8585 61.7421 5.05396 61.131 6.45204 60.3569Z"
      fill="#F9F9F9"
    />
    <path
      d="M31.5365 76.6938C30.1181 73.5364 27.9298 73.1901 25.7821 75.6957C24.5461 73.3123 22.4388 73.2309 20.1897 73.5771C19.9668 73.6179 19.744 73.6383 19.5008 73.6383C17.3936 73.6994 17.3125 73.8216 16.6844 71.9272C16.0765 70.0939 14.6582 69.2179 12.1457 68.7902C11.9633 68.1587 11.7607 67.5068 11.5176 66.7124C12.6725 66.5698 13.7464 66.4272 14.8405 66.305C16.8262 66.0606 17.7178 66.0402 19.1361 64.2272C19.9871 64.9402 20.98 64.8791 22.0133 64.9402C23.0264 65.0013 23.999 65.4902 25.1539 65.8365C24.4448 66.2235 23.8977 66.5087 23.3911 66.855C21.9323 67.8124 22.0336 69.4827 23.614 70.2161C24.1611 70.4605 24.85 70.6235 25.4173 70.542C27.1599 70.2975 28.8619 69.9105 30.5234 69.5846C30.4626 70.1753 30.2802 70.8883 30.3208 71.5605C30.3816 72.3957 31.0907 72.7012 31.9215 72.8642C31.6783 74.0864 32.2051 75.3901 31.5162 76.7345L31.5365 76.6938Z"
      fill="#F9F9F9"
    />
    <path
      d="M33.9274 43.2052C34.9203 43.2052 35.751 43.4496 36.217 44.4885C36.521 45.1811 37.2099 45.3848 37.9191 45.4255C39.4184 45.5274 40.0668 46.3626 39.8034 47.87C39.7021 48.3996 39.5603 48.97 39.2766 49.3977C37.7975 51.5162 37.3315 53.9607 36.8249 56.4458C36.4602 58.218 35.8118 59.9495 35.1634 61.6606C34.6366 63.0254 33.5424 63.3717 32.2457 62.7198C31.6986 62.455 31.192 62.0476 30.7058 61.6606C29.6724 60.7847 29.6319 60.4791 30.4423 59.3588C31.5973 57.7699 32.4078 56.018 32.7522 54.0625C33.1575 51.8829 32.9954 49.744 32.3267 47.6255C32.0836 46.8718 31.9215 46.0774 31.881 45.2829C31.8202 43.7959 32.4483 43.2052 33.9274 43.2052Z"
      fill="#F9F9F9"
    />
    <path
      d="M32.043 89.0178C33.1575 86.6548 32.7522 84.4548 32.3672 82.2345C32.2254 81.44 32.1646 80.6456 31.9417 79.8715C31.2731 77.4475 35.0216 73.842 37.3517 75.1457C38.1825 75.6142 39.1145 75.9197 39.9453 76.3882C40.5734 76.7549 40.8571 77.3864 40.7963 78.1401C40.5936 80.7678 40.391 83.3752 40.1884 86.003C40.1074 86.9604 39.6008 87.653 38.7093 87.9178C37.0883 88.4067 35.4268 88.8344 33.7653 89.1807C33.279 89.2826 32.732 89.0789 32.0228 88.9974L32.043 89.0178Z"
      fill="#F9F9F9"
    />
    <path
      d="M24.1408 44.733C22.682 44.7533 21.8512 43.7348 22.0944 42.3496C22.2159 41.6367 22.4591 40.9034 22.7833 40.2311C24.1814 37.1756 24.85 33.9571 24.9513 30.596C24.9716 29.6794 25.1945 28.722 25.4781 27.8461C25.7213 27.1331 26.2278 26.6238 27.1194 27.0516C27.9298 27.4183 28.7809 27.7442 29.6319 28.0701C29.794 28.1312 29.9358 28.2331 30.0776 28.2942C31.3136 28.8238 31.3744 28.8442 31.0705 30.2294C30.8678 31.2071 30.5437 32.1645 30.1384 33.0608C29.0848 35.3627 28.8619 37.7663 29.0848 40.2719C29.1658 41.1885 29.0443 42.1459 28.8416 43.0626C28.6593 43.9181 28.0514 44.407 27.1194 44.4681C26.0657 44.5293 25.0324 44.6515 24.1611 44.733H24.1408Z"
      fill="#F9F9F9"
    />
    <path
      d="M9.16716 27.8053C11.9633 29.1294 12.7535 28.722 14.6177 27.7035C14.8608 28.5386 15.2458 29.3331 15.3066 30.1479C15.4079 31.3701 15.3471 32.6127 15.3066 33.8349C15.266 35.4849 15.6915 36.9719 16.6439 38.3571C18.0217 40.3534 17.5151 43.3885 15.6105 44.8959C15.2863 45.1607 14.7798 45.3033 14.3543 45.3237C13.1183 45.3848 12.0241 44.9163 11.0515 44.1626C10.1397 43.4496 10.0992 42.5126 10.4437 41.5348C11.011 39.8848 11.2542 38.1941 11.011 36.4627C10.8084 35.0571 10.4437 33.6719 10.1195 32.2868C9.87633 31.2479 9.5724 30.2497 9.32926 29.2109C9.22795 28.7831 9.22794 28.3349 9.16716 27.8257V27.8053Z"
      fill="#F9F9F9"
    />
    <path
      d="M31.7189 50.8236C31.8607 53.4718 31.2528 55.8551 29.8142 58.0143C29.2469 58.8699 28.558 59.6643 28.6188 60.4587C27.9298 61.3347 27.4233 62.0272 26.856 62.6587C26.3291 63.2495 25.7415 63.7995 25.1337 64.3087C24.9716 64.4309 24.5866 64.3698 24.3435 64.268C23.3304 63.8198 22.3375 63.5346 21.2028 63.6976C20.4329 63.7995 19.8655 63.3921 19.6021 62.6791C19.3793 62.0884 19.2374 61.4773 19.1158 60.8661C19.0753 60.6828 19.2172 60.4587 19.3387 60.2958C19.7642 59.6439 20.21 58.9921 20.6558 58.3606C20.7773 58.1977 20.9597 58.0143 21.1218 57.9736C22.0336 57.7495 22.682 57.2199 23.2088 56.4662C23.4114 56.2014 23.6545 55.9366 23.9382 55.7532C26.471 54.144 29.024 52.5347 31.6783 50.8848L31.7189 50.8236Z"
      fill="#F9F9F9"
    />
    <path
      d="M22.8035 88.977C24.8095 87.9993 25.316 86.1659 25.5997 84.2715C25.9036 82.2141 25.9847 80.116 26.3494 78.079C26.5115 77.2234 26.9978 76.2864 27.6259 75.6753C28.5174 74.8197 29.6521 75.1864 30.0371 76.3882C30.3613 77.4475 30.5234 78.5475 30.726 79.6475C30.9894 81.1752 31.1313 82.703 31.4555 84.2104C31.881 86.2678 31.2326 88.1215 30.4221 89.914C29.8953 91.0955 29.2874 91.177 28.1122 90.6677C27.5449 90.4233 26.7546 90.3215 26.1873 90.5048C25.3566 90.7696 24.769 90.6677 24.1814 90.0974C23.7964 89.7307 23.3709 89.3844 22.8441 88.9363L22.8035 88.977Z"
      fill="#F9F9F9"
    />
    <path
      d="M29.6926 48.0533C29.9155 48.0533 30.1789 47.9922 30.3613 48.0533C30.6855 48.1959 31.1515 48.3792 31.2528 48.644C31.3541 48.9088 31.1515 49.4588 30.9084 49.6218C28.8214 51.0681 26.6736 52.4125 24.5866 53.8384C23.6343 54.4903 22.763 55.2643 21.9323 56.0588C21.3649 56.5884 19.6427 57.0977 18.9943 56.7106C18.7917 56.5884 18.6093 56.2217 18.6093 55.9366C18.5688 54.5718 17.8191 53.7162 16.6439 53.1866C16.0765 52.9421 15.4889 52.6977 14.9216 52.4329C14.334 52.1477 13.9287 51.6181 14.1314 50.9866C14.2732 50.4977 14.6582 49.9477 15.0837 49.744C15.7321 49.4385 16.502 49.357 17.2315 49.2755C20.4531 48.9292 23.6748 48.6033 26.8965 48.2774C27.8285 48.1755 28.7606 48.094 29.6926 47.9922C29.6926 47.9922 29.6926 48.0126 29.6926 48.0329V48.0533Z"
      fill="#F9F9F9"
    />
    <path
      d="M40.9381 49.0514C41.2015 49.5607 41.3839 49.9477 41.6068 50.3144C41.9917 50.9255 42.3159 51.5977 42.8225 52.107C43.7343 53.0033 43.6735 54.0421 43.4506 55.1218C43.3696 55.5292 43.2075 55.9569 43.0048 56.3236C41.7081 58.5236 41.3028 60.9476 40.9584 63.4328C40.8368 64.2883 40.4923 65.1235 40.1479 65.9383C39.8845 66.5494 39.3779 66.7124 38.7295 66.3457C37.8177 65.8365 36.8452 65.3883 35.9334 64.8995C35.3052 64.5735 35.1634 64.0235 35.5686 63.4124C37.2099 60.9273 37.9393 58.0958 38.3648 55.2236C38.7093 52.9625 39.7629 51.1088 40.9584 49.0514H40.9381Z"
      fill="#F9F9F9"
    />
    <path
      d="M12.9359 16.6628C12.6928 19.0869 12.6725 21.5109 13.4222 23.8739C13.564 24.3424 13.7464 24.7905 13.868 25.259C14.1111 26.1757 13.8072 26.8072 12.9156 27.1738C11.3555 27.8257 9.41031 26.9294 9.02533 25.1776C8.76192 23.9757 8.84297 22.6924 8.94428 21.4498C9.10637 19.2498 9.10637 17.111 8.05275 15.0943C7.62724 14.2999 7.48541 13.5462 8.25537 12.8536C9.08611 12.0795 10.1397 11.8758 11.0718 12.3851C12.3078 13.0573 12.8346 14.1777 12.9156 15.5628C12.9156 15.9295 12.9156 16.2962 12.9156 16.6832L12.9359 16.6628Z"
      fill="#F9F9F9"
    />
    <path
      d="M33.8666 13.811C33.6438 14.3814 33.4817 14.9517 33.1777 15.461C31.8202 17.7628 31.7594 20.2276 32.1646 22.7535C32.2862 23.5072 32.3875 24.2813 32.5496 25.035C32.7117 25.809 32.428 26.359 31.8202 26.8072C31.2326 27.2553 30.7058 27.2757 30.0371 26.8887C29.2064 26.4201 28.2743 26.1146 27.3625 25.809C26.1468 25.4016 25.8226 24.8109 26.3089 23.6091C26.552 23.0387 26.7952 22.448 27.0991 21.898C28.4567 19.4535 28.9835 16.785 29.1861 14.0351C29.1861 13.811 29.2266 13.5666 29.2874 13.3628C29.5913 12.4054 29.9358 12.2221 30.8678 12.5684C31.8404 12.9351 32.7928 13.3425 33.8464 13.7703L33.8666 13.811Z"
      fill="#F9F9F9"
    />
    <path
      d="M8.5593 0C8.88349 0.712957 9.24821 1.40554 9.55214 2.13887C10.626 4.66478 11.396 7.29253 11.8215 10.0018C12.0039 11.1425 11.9431 11.0814 10.8489 10.9999C9.97765 10.9388 9.06585 11.1221 8.21484 11.3869C7.44488 11.6314 7.24226 11.6314 7.12069 10.7555C6.83702 8.69807 6.9586 6.66105 7.42462 4.62403C7.66777 3.54441 7.76908 2.42405 7.95144 1.34443C8.03249 0.896289 8.15406 0.468515 8.27563 0.0203702C8.37694 0.0203702 8.45799 0.0203702 8.5593 0.0203702V0Z"
      fill="#F9F9F9"
    />
    <path
      d="M34.13 64.3902C32.5901 66.0198 31.2934 67.8531 29.1456 68.5661C28.0312 68.9327 26.8154 69.0346 25.6402 69.2383C25.4376 69.279 25.2147 69.3605 25.0526 69.279C24.5663 69.055 24.1206 68.7494 23.6545 68.4846C23.9787 68.1587 24.2219 67.6902 24.6069 67.5272C26.3697 66.855 27.322 65.5513 27.7678 63.7791C27.788 63.6976 27.8083 63.6161 27.8488 63.5346C28.4769 61.8643 28.8822 61.7421 30.2195 62.8421C31.3136 63.7587 32.5293 64.3902 34.13 64.3495V64.3902Z"
      fill="#F9F9F9"
    />
    <path
      d="M34.7582 1.50739C34.9 2.89257 35.1431 4.13515 35.1026 5.37773C35.0418 7.3129 34.8392 9.24807 34.6163 11.1832C34.4542 12.5277 34.0895 12.6703 32.8738 12.0592C32.4888 11.8758 32.1038 11.6721 31.6986 11.5295C30.6855 11.1832 30.5639 10.8981 30.9692 9.83881C31.496 8.45363 32.0228 7.06846 32.5091 5.66291C32.7725 4.88885 32.9143 4.05367 33.1575 3.2796C33.3803 2.56664 33.7451 1.93517 34.7582 1.50739Z"
      fill="#F9F9F9"
    />
    <path
      d="M31.5162 71.3568C31.5973 70.9901 31.5973 70.5827 31.7796 70.2772C32.5091 69.055 33.2588 67.8531 34.0287 66.6513C34.5758 65.8161 34.8595 65.7957 35.7915 66.2439C36.9059 66.7735 38.0609 67.2828 39.1956 67.792C39.7629 68.0365 39.9453 68.9939 39.3577 69.442C38.7498 69.8901 37.9798 70.155 37.2301 70.3383C36.6425 70.4809 35.9739 70.2975 35.366 70.4198C34.4137 70.6031 33.4614 70.9087 32.5293 71.1735C32.2659 71.255 32.0228 71.3772 31.7594 71.479C31.6783 71.4383 31.577 71.3772 31.496 71.3364L31.5162 71.3568Z"
      fill="#F9F9F9"
    />
    <path
      d="M17.9001 44.5293C18.2243 45.6496 18.5485 46.77 18.893 47.9718C16.4412 48.1755 13.6856 47.6866 12.6725 50.9459H11.6797C11.1326 49.6218 10.5855 48.3996 10.1195 47.1366C9.99791 46.8311 10.0587 46.2607 10.2816 46.0774C10.5247 45.8533 11.0515 45.8329 11.4163 45.8941C12.4699 46.0978 13.483 46.5459 14.5366 46.5866C15.9347 46.6478 16.8668 45.67 17.5962 44.5293C17.6975 44.5293 17.7988 44.5293 17.9001 44.5293Z"
      fill="#F9F9F9"
    />
    <path
      d="M33.1777 74.7994C33.0156 72.0901 33.2993 71.8049 35.7105 71.6827C36.7844 71.6216 37.8583 71.479 38.9119 71.2957C40.1074 71.092 41.0597 71.8253 41.1205 73.1697C41.161 73.9031 41.0192 74.6364 40.9584 75.4512C38.4459 74.1475 36.0144 72.5994 33.1575 74.7994H33.1777Z"
      fill="#F9F9F9"
    />
    <path
      d="M11.3149 65.4494C11.2339 64.0846 11.9025 62.9847 13.3006 62.4754C14.6987 61.9661 16.1778 61.6402 17.5962 61.2328C18.6093 62.9846 18.0014 64.492 16.0765 64.8383C14.5164 65.1235 12.9359 65.2457 11.2947 65.4494H11.3149Z"
      fill="#F9F9F9"
    />
    <path
      d="M21.4257 44.9978C23.0669 46.4033 25.0729 45.9959 28.3554 45.5274C28.7809 46.5052 28.558 46.8515 27.5651 46.9737C25.6402 47.2181 23.7356 47.5033 21.8107 47.7681C21.5878 47.8089 21.3649 47.8089 20.9192 47.8496C21.0813 46.8718 21.2434 45.9959 21.4055 44.9978H21.4257Z"
      fill="#F9F9F9"
    />
    <path
      d="M41.1407 70.5216C40.9786 70.4401 40.7557 70.3383 40.4518 70.1753C40.9786 69.3198 41.0597 68.4642 40.7355 67.4865C40.6342 67.1809 41.161 66.6513 41.4447 66.1624C42.0323 66.8957 42.093 67.5272 41.7486 68.3013C41.4447 68.9735 41.3433 69.7476 41.1407 70.5216Z"
      fill="#F9F9F9"
    />
    <path
      d="M0.717873 69.1161C1.16364 69.1161 1.64993 69.2179 2.05517 69.0957C3.29116 68.7087 4.04085 69.2179 4.72976 70.1753C5.58077 71.3568 6.71545 72.1716 8.17432 72.3346C9.7345 72.5179 10.9907 71.9679 11.7607 70.5624C12.0039 70.1142 12.247 70.0327 12.713 70.1346C14.1922 70.4198 15.1647 71.2753 15.6713 72.6809C16.3805 74.6364 16.9478 75.0438 19.0145 74.9216C19.9871 74.8605 20.9394 74.7179 21.912 74.6975C23.614 74.6568 24.6474 75.4716 24.8703 77.1419C25.0324 78.4049 24.9918 79.7086 24.8703 80.9715C24.7082 82.4789 24.3637 83.9659 24.0598 85.4326C23.7761 86.8178 22.9454 87.8159 21.5676 88.0807C19.9061 88.4067 18.204 88.6918 16.5223 88.7122C14.719 88.7122 13.2601 87.7141 12.1254 86.3696C11.1731 85.2289 10.3626 83.9863 9.45083 82.8049C6.99912 79.6475 4.48662 76.5308 2.05517 73.3735C1.46757 72.6198 0.961018 71.7642 0.5963 70.8883C0.373417 70.3587 0.474728 69.7068 0.434204 69.1161C0.535515 69.1161 0.616562 69.1161 0.717873 69.1364V69.1161ZM9.26847 77.7123C10.5247 82.6215 15.7929 86.9807 20.5747 85.5141C15.266 84.9437 12.1862 81.44 9.26847 77.7123Z"
      fill="#F9F9F9"
    />
    <path
      d="M6.45204 60.3569C5.90497 61.4365 5.47946 62.2717 5.05396 63.1272C5.13501 63.1883 5.21605 63.2698 5.27684 63.3309C5.92523 62.6587 6.6344 62.0476 7.222 61.3347C8.3972 59.8884 8.5593 58.0347 9.00506 56.3032C9.24821 55.3662 9.43057 54.4088 9.77502 53.4921C10.1803 52.3921 11.9431 51.7199 12.9359 52.2903C13.2196 52.4533 13.3411 52.881 13.6046 53.044C14.4353 53.5329 15.2863 54.0014 16.1576 54.3884C16.887 54.7144 17.272 55.2032 17.353 55.9977C17.4949 57.4847 17.8799 57.8717 19.6224 58.1365C19.0956 58.6662 18.7714 59.2569 18.2851 59.4606C17.0086 59.9902 15.6713 60.3365 14.3543 60.805C13.564 61.0902 12.7333 61.355 12.0039 61.7828C10.2816 62.8217 9.61293 64.7365 10.1803 66.692C10.3424 67.2624 10.5652 67.8328 10.7273 68.4031C11.1934 70.0735 9.99791 71.479 8.31615 71.0716C7.48541 70.8679 6.73571 70.2772 6.00628 69.7476C5.58077 69.442 5.31736 68.9327 4.93238 68.5253C4.284 67.8531 3.51404 67.5476 2.54146 67.7513C1.04207 68.0568 0.636823 67.6902 0.677347 66.142C0.717871 64.5328 1.32574 63.2495 2.72382 62.4143C3.8585 61.7421 5.05396 61.131 6.45204 60.3569Z"
      fill="#F9F9F9"
    />
    <path
      d="M31.5365 76.6938C30.1181 73.5364 27.9298 73.1901 25.7821 75.6957C24.5461 73.3123 22.4388 73.2309 20.1897 73.5771C19.9668 73.6179 19.744 73.6383 19.5008 73.6383C17.3936 73.6994 17.3125 73.8216 16.6844 71.9272C16.0765 70.0939 14.6582 69.2179 12.1457 68.7902C11.9633 68.1587 11.7607 67.5068 11.5176 66.7124C12.6725 66.5698 13.7464 66.4272 14.8405 66.305C16.8262 66.0606 17.7178 66.0402 19.1361 64.2272C19.9871 64.9402 20.98 64.8791 22.0133 64.9402C23.0264 65.0013 23.999 65.4902 25.1539 65.8365C24.4448 66.2235 23.8977 66.5087 23.3911 66.855C21.9323 67.8124 22.0336 69.4827 23.614 70.2161C24.1611 70.4605 24.85 70.6235 25.4173 70.542C27.1599 70.2975 28.8619 69.9105 30.5234 69.5846C30.4626 70.1753 30.2802 70.8883 30.3208 71.5605C30.3816 72.3957 31.0907 72.7012 31.9215 72.8642C31.6783 74.0864 32.2051 75.3901 31.5162 76.7345L31.5365 76.6938Z"
      fill="#F9F9F9"
    />
    <path
      d="M33.9274 43.2052C34.9203 43.2052 35.751 43.4496 36.217 44.4885C36.521 45.1811 37.2099 45.3848 37.9191 45.4255C39.4184 45.5274 40.0668 46.3626 39.8034 47.87C39.7021 48.3996 39.5603 48.97 39.2766 49.3977C37.7975 51.5162 37.3315 53.9607 36.8249 56.4458C36.4602 58.218 35.8118 59.9495 35.1634 61.6606C34.6366 63.0254 33.5424 63.3717 32.2457 62.7198C31.6986 62.455 31.192 62.0476 30.7058 61.6606C29.6724 60.7847 29.6319 60.4791 30.4423 59.3588C31.5973 57.7699 32.4078 56.018 32.7522 54.0625C33.1575 51.8829 32.9954 49.744 32.3267 47.6255C32.0836 46.8718 31.9215 46.0774 31.881 45.2829C31.8202 43.7959 32.4483 43.2052 33.9274 43.2052Z"
      fill="#F9F9F9"
    />
    <path
      d="M32.043 89.0178C33.1575 86.6548 32.7522 84.4548 32.3672 82.2345C32.2254 81.44 32.1646 80.6456 31.9417 79.8715C31.2731 77.4475 35.0216 73.842 37.3517 75.1457C38.1825 75.6142 39.1145 75.9197 39.9453 76.3882C40.5734 76.7549 40.8571 77.3864 40.7963 78.1401C40.5936 80.7678 40.391 83.3752 40.1884 86.003C40.1074 86.9604 39.6008 87.653 38.7093 87.9178C37.0883 88.4067 35.4268 88.8344 33.7653 89.1807C33.279 89.2826 32.732 89.0789 32.0228 88.9974L32.043 89.0178Z"
      fill="#F9F9F9"
    />
    <path
      d="M24.1408 44.733C22.682 44.7533 21.8512 43.7348 22.0944 42.3496C22.2159 41.6367 22.4591 40.9034 22.7833 40.2311C24.1814 37.1756 24.85 33.9571 24.9513 30.596C24.9716 29.6794 25.1945 28.722 25.4781 27.8461C25.7213 27.1331 26.2278 26.6238 27.1194 27.0516C27.9298 27.4183 28.7809 27.7442 29.6319 28.0701C29.794 28.1312 29.9358 28.2331 30.0776 28.2942C31.3136 28.8238 31.3744 28.8442 31.0705 30.2294C30.8678 31.2071 30.5437 32.1645 30.1384 33.0608C29.0848 35.3627 28.8619 37.7663 29.0848 40.2719C29.1658 41.1885 29.0443 42.1459 28.8416 43.0626C28.6593 43.9181 28.0514 44.407 27.1194 44.4681C26.0657 44.5293 25.0324 44.6515 24.1611 44.733H24.1408Z"
      fill="#F9F9F9"
    />
    <path
      d="M9.16716 27.8053C11.9633 29.1294 12.7535 28.722 14.6177 27.7035C14.8608 28.5386 15.2458 29.3331 15.3066 30.1479C15.4079 31.3701 15.3471 32.6127 15.3066 33.8349C15.266 35.4849 15.6915 36.9719 16.6439 38.3571C18.0217 40.3534 17.5151 43.3885 15.6105 44.8959C15.2863 45.1607 14.7798 45.3033 14.3543 45.3237C13.1183 45.3848 12.0241 44.9163 11.0515 44.1626C10.1397 43.4496 10.0992 42.5126 10.4437 41.5348C11.011 39.8848 11.2542 38.1941 11.011 36.4627C10.8084 35.0571 10.4437 33.6719 10.1195 32.2868C9.87633 31.2479 9.5724 30.2497 9.32926 29.2109C9.22795 28.7831 9.22794 28.3349 9.16716 27.8257V27.8053Z"
      fill="#F9F9F9"
    />
    <path
      d="M31.7189 50.8236C31.8607 53.4718 31.2528 55.8551 29.8142 58.0143C29.2469 58.8699 28.558 59.6643 28.6188 60.4587C27.9298 61.3347 27.4233 62.0272 26.856 62.6587C26.3291 63.2495 25.7415 63.7995 25.1337 64.3087C24.9716 64.4309 24.5866 64.3698 24.3435 64.268C23.3304 63.8198 22.3375 63.5346 21.2028 63.6976C20.4329 63.7995 19.8655 63.3921 19.6021 62.6791C19.3793 62.0884 19.2374 61.4773 19.1158 60.8661C19.0753 60.6828 19.2172 60.4587 19.3387 60.2958C19.7642 59.6439 20.21 58.9921 20.6558 58.3606C20.7773 58.1977 20.9597 58.0143 21.1218 57.9736C22.0336 57.7495 22.682 57.2199 23.2088 56.4662C23.4114 56.2014 23.6545 55.9366 23.9382 55.7532C26.471 54.144 29.024 52.5347 31.6783 50.8848L31.7189 50.8236Z"
      fill="#F9F9F9"
    />
    <path
      d="M22.8035 88.977C24.8095 87.9993 25.316 86.1659 25.5997 84.2715C25.9036 82.2141 25.9847 80.116 26.3494 78.079C26.5115 77.2234 26.9978 76.2864 27.6259 75.6753C28.5174 74.8197 29.6521 75.1864 30.0371 76.3882C30.3613 77.4475 30.5234 78.5475 30.726 79.6475C30.9894 81.1752 31.1313 82.703 31.4555 84.2104C31.881 86.2678 31.2326 88.1215 30.4221 89.914C29.8953 91.0955 29.2874 91.177 28.1122 90.6677C27.5449 90.4233 26.7546 90.3215 26.1873 90.5048C25.3566 90.7696 24.769 90.6677 24.1814 90.0974C23.7964 89.7307 23.3709 89.3844 22.8441 88.9363L22.8035 88.977Z"
      fill="#F9F9F9"
    />
    <path
      d="M29.6926 48.0533C29.9155 48.0533 30.1789 47.9922 30.3613 48.0533C30.6855 48.1959 31.1515 48.3792 31.2528 48.644C31.3541 48.9088 31.1515 49.4588 30.9084 49.6218C28.8214 51.0681 26.6736 52.4125 24.5866 53.8384C23.6343 54.4903 22.763 55.2643 21.9323 56.0588C21.3649 56.5884 19.6427 57.0977 18.9943 56.7106C18.7917 56.5884 18.6093 56.2217 18.6093 55.9366C18.5688 54.5718 17.8191 53.7162 16.6439 53.1866C16.0765 52.9421 15.4889 52.6977 14.9216 52.4329C14.334 52.1477 13.9287 51.6181 14.1314 50.9866C14.2732 50.4977 14.6582 49.9477 15.0837 49.744C15.7321 49.4385 16.502 49.357 17.2315 49.2755C20.4531 48.9292 23.6748 48.6033 26.8965 48.2774C27.8285 48.1755 28.7606 48.094 29.6926 47.9922C29.6926 47.9922 29.6926 48.0126 29.6926 48.0329V48.0533Z"
      fill="#F9F9F9"
    />
    <path
      d="M40.9381 49.0514C41.2015 49.5607 41.3839 49.9477 41.6068 50.3144C41.9917 50.9255 42.3159 51.5977 42.8225 52.107C43.7343 53.0033 43.6735 54.0421 43.4506 55.1218C43.3696 55.5292 43.2075 55.9569 43.0048 56.3236C41.7081 58.5236 41.3028 60.9476 40.9584 63.4328C40.8368 64.2883 40.4923 65.1235 40.1479 65.9383C39.8845 66.5494 39.3779 66.7124 38.7295 66.3457C37.8177 65.8365 36.8452 65.3883 35.9334 64.8995C35.3052 64.5735 35.1634 64.0235 35.5686 63.4124C37.2099 60.9273 37.9393 58.0958 38.3648 55.2236C38.7093 52.9625 39.7629 51.1088 40.9584 49.0514H40.9381Z"
      fill="#F9F9F9"
    />
    <path
      d="M12.9359 16.6628C12.6928 19.0869 12.6725 21.5109 13.4222 23.8739C13.564 24.3424 13.7464 24.7905 13.868 25.259C14.1111 26.1757 13.8072 26.8072 12.9156 27.1738C11.3555 27.8257 9.41031 26.9294 9.02533 25.1776C8.76192 23.9757 8.84297 22.6924 8.94428 21.4498C9.10637 19.2498 9.10637 17.111 8.05275 15.0943C7.62724 14.2999 7.48541 13.5462 8.25537 12.8536C9.08611 12.0795 10.1397 11.8758 11.0718 12.3851C12.3078 13.0573 12.8346 14.1777 12.9156 15.5628C12.9156 15.9295 12.9156 16.2962 12.9156 16.6832L12.9359 16.6628Z"
      fill="#F9F9F9"
    />
    <path
      d="M33.8666 13.811C33.6438 14.3814 33.4817 14.9517 33.1777 15.461C31.8202 17.7628 31.7594 20.2276 32.1646 22.7535C32.2862 23.5072 32.3875 24.2813 32.5496 25.035C32.7117 25.809 32.428 26.359 31.8202 26.8072C31.2326 27.2553 30.7058 27.2757 30.0371 26.8887C29.2064 26.4201 28.2743 26.1146 27.3625 25.809C26.1468 25.4016 25.8226 24.8109 26.3089 23.6091C26.552 23.0387 26.7952 22.448 27.0991 21.898C28.4567 19.4535 28.9835 16.785 29.1861 14.0351C29.1861 13.811 29.2266 13.5666 29.2874 13.3628C29.5913 12.4054 29.9358 12.2221 30.8678 12.5684C31.8404 12.9351 32.7928 13.3425 33.8464 13.7703L33.8666 13.811Z"
      fill="#F9F9F9"
    />
    <path
      d="M8.5593 0C8.88349 0.712957 9.24821 1.40554 9.55214 2.13887C10.626 4.66478 11.396 7.29253 11.8215 10.0018C12.0039 11.1425 11.9431 11.0814 10.8489 10.9999C9.97765 10.9388 9.06585 11.1221 8.21484 11.3869C7.44488 11.6314 7.24226 11.6314 7.12069 10.7555C6.83702 8.69807 6.9586 6.66105 7.42462 4.62403C7.66777 3.54441 7.76908 2.42405 7.95144 1.34443C8.03249 0.896289 8.15406 0.468515 8.27563 0.0203702C8.37694 0.0203702 8.45799 0.0203702 8.5593 0.0203702V0Z"
      fill="#F9F9F9"
    />
    <path
      d="M34.13 64.3902C32.5901 66.0198 31.2934 67.8531 29.1456 68.5661C28.0312 68.9327 26.8154 69.0346 25.6402 69.2383C25.4376 69.279 25.2147 69.3605 25.0526 69.279C24.5663 69.055 24.1206 68.7494 23.6545 68.4846C23.9787 68.1587 24.2219 67.6902 24.6069 67.5272C26.3697 66.855 27.322 65.5513 27.7678 63.7791C27.788 63.6976 27.8083 63.6161 27.8488 63.5346C28.4769 61.8643 28.8822 61.7421 30.2195 62.8421C31.3136 63.7587 32.5293 64.3902 34.13 64.3495V64.3902Z"
      fill="#F9F9F9"
    />
    <path
      d="M34.7582 1.50739C34.9 2.89257 35.1431 4.13515 35.1026 5.37773C35.0418 7.3129 34.8392 9.24807 34.6163 11.1832C34.4542 12.5277 34.0895 12.6703 32.8738 12.0592C32.4888 11.8758 32.1038 11.6721 31.6986 11.5295C30.6855 11.1832 30.5639 10.8981 30.9692 9.83881C31.496 8.45363 32.0228 7.06846 32.5091 5.66291C32.7725 4.88885 32.9143 4.05367 33.1575 3.2796C33.3803 2.56664 33.7451 1.93517 34.7582 1.50739Z"
      fill="#F9F9F9"
    />
    <path
      d="M31.5162 71.3568C31.5973 70.9901 31.5973 70.5827 31.7796 70.2772C32.5091 69.055 33.2588 67.8531 34.0287 66.6513C34.5758 65.8161 34.8595 65.7957 35.7915 66.2439C36.9059 66.7735 38.0609 67.2828 39.1956 67.792C39.7629 68.0365 39.9453 68.9939 39.3577 69.442C38.7498 69.8901 37.9798 70.155 37.2301 70.3383C36.6425 70.4809 35.9739 70.2975 35.366 70.4198C34.4137 70.6031 33.4614 70.9087 32.5293 71.1735C32.2659 71.255 32.0228 71.3772 31.7594 71.479C31.6783 71.4383 31.577 71.3772 31.496 71.3364L31.5162 71.3568Z"
      fill="#F9F9F9"
    />
    <path
      d="M17.9001 44.5293C18.2243 45.6496 18.5485 46.77 18.893 47.9718C16.4412 48.1755 13.6856 47.6866 12.6725 50.9459H11.6797C11.1326 49.6218 10.5855 48.3996 10.1195 47.1366C9.99791 46.8311 10.0587 46.2607 10.2816 46.0774C10.5247 45.8533 11.0515 45.8329 11.4163 45.8941C12.4699 46.0978 13.483 46.5459 14.5366 46.5866C15.9347 46.6478 16.8668 45.67 17.5962 44.5293C17.6975 44.5293 17.7988 44.5293 17.9001 44.5293Z"
      fill="#F9F9F9"
    />
    <path
      d="M33.1777 74.7994C33.0156 72.0901 33.2993 71.8049 35.7105 71.6827C36.7844 71.6216 37.8583 71.479 38.9119 71.2957C40.1074 71.092 41.0597 71.8253 41.1205 73.1697C41.161 73.9031 41.0192 74.6364 40.9584 75.4512C38.4459 74.1475 36.0144 72.5994 33.1575 74.7994H33.1777Z"
      fill="#F9F9F9"
    />
    <path
      d="M11.3149 65.4494C11.2339 64.0846 11.9025 62.9847 13.3006 62.4754C14.6987 61.9661 16.1778 61.6402 17.5962 61.2328C18.6093 62.9846 18.0014 64.492 16.0765 64.8383C14.5164 65.1235 12.9359 65.2457 11.2947 65.4494H11.3149Z"
      fill="#F9F9F9"
    />
    <path
      d="M21.4257 44.9978C23.0669 46.4033 25.0729 45.9959 28.3554 45.5274C28.7809 46.5052 28.558 46.8515 27.5651 46.9737C25.6402 47.2181 23.7356 47.5033 21.8107 47.7681C21.5878 47.8089 21.3649 47.8089 20.9192 47.8496C21.0813 46.8718 21.2434 45.9959 21.4055 44.9978H21.4257Z"
      fill="#F9F9F9"
    />
    <path
      d="M41.1407 70.5216C40.9786 70.4401 40.7557 70.3383 40.4518 70.1753C40.9786 69.3198 41.0597 68.4642 40.7355 67.4865C40.6342 67.1809 41.161 66.6513 41.4447 66.1624C42.0323 66.8957 42.093 67.5272 41.7486 68.3013C41.4447 68.9735 41.3433 69.7476 41.1407 70.5216Z"
      fill="#F9F9F9"
    />
  </svg>
)

export default Peace
