import React, { useEffect, useRef } from 'react'
import Lottie from 'lottie-react'
import { useIsInViewport } from '@superrb/gatsby-addons/hooks'

interface Props {
  className?: string
  animationData?: Object
}

const AnimatedIcon = ({ className = '', animationData = {} }: Props) => {
  const { isInViewport, setRef } = useIsInViewport(true)
  const lottieRef = useRef()

  className = `animated-icon ${className}`

  useEffect(() => {
    isInViewport ? lottieRef?.current?.play() : lottieRef?.current?.pause()
  }, [isInViewport])

  return (
    <div className={className} ref={setRef}>
      <Lottie lottieRef={lottieRef} animationData={animationData} loop={true} />
    </div>
  )
}

export default AnimatedIcon
