import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  MouseEventHandler,
  ReactNode,
} from 'react'

type ParentProps =
  | ButtonHTMLAttributes<HTMLButtonElement> &
      AnchorHTMLAttributes<HTMLAnchorElement>
interface Props extends ParentProps {
  children: ReactNode
  label: string
  href?: string
  className?: string
  onClick?: MouseEventHandler<Element>
}

const IconButton = ({
  children,
  label,
  href = undefined,
  className = '',
  onClick = (event) => {},
  ...props
}: Props) => {
  const content = (
    <>
      <span className="screenreader-text">{label}</span>
      {children}
    </>
  )
  if (href) {
    return (
      <a
        className={`icon-button ${className}`}
        href={href}
        {...(props as AnchorHTMLAttributes<HTMLAnchorElement>)}
      >
        {content}
      </a>
    )
  }

  return (
    <button
      className={`icon-button ${className}`}
      onClick={onClick}
      {...(props as ButtonHTMLAttributes<HTMLButtonElement>)}
    >
      {content}
    </button>
  )
}

export default IconButton
