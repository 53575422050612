import { useIsInViewport } from '@superrb/gatsby-addons/hooks'
import React from 'react'
import { LargeCarouselSectionData } from '../../types/pages/homepage'
import Reepers from '../icons/reepers.svg'
import { RichText } from '@superrb/gatsby-addons/components'

const LargeCarouselSection = ({
  large_carousel_text: text,
}: LargeCarouselSectionData) => {
  const { isInViewport, setRef } = useIsInViewport(false)

  return (
    <section
      className="large-carousel-section"
      aria-label={text.text}
      ref={setRef}
    >
      <div
        className="large-carousel-section__inner"
        style={{ animationPlayState: isInViewport ? 'running' : 'paused' }}
      >
        {Array.from(Array(8)).map((item, i) => (
          <div
            className="large-carousel-section__item"
            key={i}
            aria-hidden={i !== 0}
          >
            <div className="large-carousel-section__item-text">
              <RichText field={text.richText} />
            </div>
            <div className="large-carousel-section__item-icon">
              <Reepers />
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default LargeCarouselSection
