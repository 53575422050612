import { Button, Image } from '@superrb/gatsby-addons/components'
import React from 'react'
import { HeroSectionData } from '../../types/pages/homepage'
import GlobeMesh from '../icons/globe-mesh.svg'
import LordsOfWaterHeroLogo from '../icons/lords-of-water-hero-logo.svg'
import Peace from '../icons/peace.svg'
import bird from '../lottie/bird.json'
import AnimatedIcon from '../animated-icon'
import { ImageLayout } from '@superrb/gatsby-addons/types'

const HeroSection = ({
  hero_subtitle: subtitle,
  hero_button_label: buttonLabel,
  hero_image: image,
  image_override: imageOverride,
}: HeroSectionData) => {
  return (
    <section className="hero-section" aria-label={subtitle}>
      <div className="hero-section__inner">
        <div className="hero-section__content">
          <div className="hero-section__title">
            <h1 className="screenreader-text">The Lords of Water</h1>

            <LordsOfWaterHeroLogo />
          </div>

          {subtitle && <h2 className="hero-section__subtitle">{subtitle}</h2>}

          {buttonLabel && (
            <Button
              className="hero-section__button"
              href={'#buy-online'}
              label={<span data-text={buttonLabel}>{buttonLabel}</span>}
            />
          )}
        </div>

        {image && (
          <Image
            className={`hero-section__image ${
              imageOverride ? 'hero-section__image--desktop' : ''
            }`}
            image={image}
            layout={ImageLayout.contain}
          />
        )}
        {imageOverride && (
          <Image
            className="hero-section__image hero-section__image--override"
            image={imageOverride}
            layout={ImageLayout.contain}
          />
        )}
      </div>
    </section>
  )
}

export default HeroSection
