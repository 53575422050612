import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { SkipTo } from './skip-to'
import Header from './header'
import Footer from './footer'
import { TranslationContext } from '@superrb/gatsby-addons/context'
import Newsletter from './newsletter'

export const Layout = ({ children }) => {
  const { language } = useContext(TranslationContext)

  return (
    <>
      <Helmet>
        <html lang={language} />
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        />
        <link rel="stylesheet" href="https://use.typekit.net/rep4jyw.css" />
      </Helmet>
      <SkipTo />
      <Header />
      <main>{children}</main>
      <Footer />
      <Newsletter />
    </>
  )
}
