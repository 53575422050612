import React from 'react'

const Facebook = () => (
  <svg
    width="13"
    height="26"
    viewBox="0 0 13 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.9502 26H8.62086V12.8892H12.578L13 8.49954H8.62086V5.99961C8.62086 4.96475 8.84626 4.55562 9.93086 4.55562H13V0H9.07274C4.85273 0 2.9502 1.71525 2.9502 4.99985V8.49954H0V12.9443H2.9502V26Z"
      fill="currentColor"
    />
  </svg>
)

export default Facebook
