import React from 'react'

const Heart = () => (
  <svg
    width="30"
    height="27"
    viewBox="0 0 30 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.174 8.59033C26.1958 7.23985 25.7721 5.91098 24.9138 5.07909C24.1751 4.37684 22.9692 3.92308 21.7415 3.90148C20.6334 3.87987 19.4601 4.30122 18.6127 5.02507C18.0478 5.55446 17.472 6.08384 16.9179 6.59162L16.6898 1.61107C18.1673 0.541494 19.9925 -0.0311071 21.8176 0.00130433C22.9149 0.0229119 23.9904 0.238988 24.9681 0.627925C26.2718 1.55705 27.0106 2.78868 27.1518 4.29041C27.293 5.77053 26.8585 7.27226 26.174 8.60113V8.59033Z"
      fill="#F9F9F9"
    />
    <path
      d="M15.9293 6.8077L13.2785 6.68886L11.7793 6.62403L10.1606 6.55921L10.8885 5.81375L11.5729 5.12231C11.5729 5.12231 11.5077 5.05748 11.4642 5.02507C11.4534 5.01427 11.4316 4.99266 11.4208 4.98186C11.2361 4.809 11.0405 4.63613 10.8341 4.48488C8.98726 3.10199 6.22781 2.52939 3.81601 3.09119C2.66443 3.36129 1.66495 3.86906 0.861013 4.58212C1.25212 3.75022 1.76272 3.00476 2.38197 2.38894C3.89206 0.887216 6.05399 0.00130433 8.19419 0.00130433C8.23764 0.00130433 8.2811 0.00130433 8.33542 0.00130433C10.367 0.0337157 12.1921 0.638728 13.561 1.71911C13.7239 1.84875 13.876 1.9784 14.0281 2.11885C14.115 2.19448 14.202 2.28091 14.2889 2.35653L15.6903 0.930431V1.15731L15.7555 2.39975L15.9402 6.8077H15.9293Z"
      fill="#F9F9F9"
    />
    <path
      d="M4.94586 11.745C5.40215 12.2852 5.8693 12.8254 6.33645 13.344L3.53355 13.0415L3.46836 15.9369C2.99035 15.3967 2.5232 14.8565 2.05605 14.2947C0.698054 12.7498 -0.0515595 10.5566 0.00276028 8.28782C0.00276028 7.97451 0.0353522 7.672 0.0679441 7.3695C0.937061 5.18713 2.68616 4.36604 4.02243 4.05273C5.0545 3.81505 6.18435 3.81505 7.24902 4.02032C6.42336 4.20398 5.63029 4.62533 5.0545 5.19793C3.47923 6.77529 3.40318 10.0056 4.91327 11.7234L4.935 11.7558L4.94586 11.745Z"
      fill="#F9F9F9"
    />
    <path
      d="M18.2434 24.3099C17.5915 24.8825 16.9179 25.4551 16.2444 26.0061L15.0385 27L13.8326 26.0061C11.3013 23.9209 8.90035 21.6953 6.57546 19.3077C6.34731 19.0808 6.13003 18.8431 5.90189 18.6054L5.18487 19.2537L4.33748 20.0099L4.37007 18.3894L4.40267 16.9957L4.45698 14.1327L7.30334 14.446L8.75911 14.608L10.1932 14.7701L9.49786 15.3967L8.78084 16.0449C9.00898 16.2826 9.22626 16.5095 9.45441 16.7472C11.16 18.4974 12.9091 20.1396 14.7234 21.7169C14.756 21.7386 14.7777 21.7602 14.8103 21.7926L15.0385 21.987C16.1901 22.9594 17.3851 23.7805 18.2434 24.3315V24.3099Z"
      fill="#F9F9F9"
    />
    <path
      d="M24.7291 18.022C22.8714 20.0099 20.9702 21.8682 19.0038 23.64C18.1999 23.1214 16.9723 22.2895 15.7881 21.3172C17.9066 19.4698 19.9164 17.5143 21.8828 15.4075L21.8611 18.2489L24.7291 18.022Z"
      fill="#F9F9F9"
    />
    <path
      d="M27.8362 16.0125L28.5858 16.7256L27.0975 16.8444L25.696 16.9525L22.8171 17.1793L22.8388 14.3487V12.8902L22.8605 11.3021L23.6101 12.0151L24.3163 12.685C24.4141 12.5661 24.5119 12.4581 24.6205 12.3392C24.74 12.2204 24.8595 12.1016 24.979 11.9719C26.6629 10.1353 28.3794 7.14262 28.1078 4.19318C28.0426 3.53415 27.8797 2.90753 27.619 2.32412C29.0856 3.77183 29.9547 5.93259 29.9982 8.26621C30.0416 10.5566 29.3137 12.7714 27.9775 14.3704C27.9123 14.4676 27.8362 14.5432 27.771 14.6296C27.5646 14.8781 27.3474 15.1266 27.1409 15.3643L27.8471 16.0341L27.8362 16.0125Z"
      fill="#F9F9F9"
    />
    <path
      d="M26.174 8.59033C26.1958 7.23985 25.7721 5.91098 24.9138 5.07909C24.1751 4.37684 22.9692 3.92308 21.7415 3.90148C20.6334 3.87987 19.4601 4.30122 18.6127 5.02507C18.0478 5.55446 17.472 6.08384 16.9179 6.59162L16.6898 1.61107C18.1673 0.541494 19.9925 -0.0311071 21.8176 0.00130433C22.9149 0.0229119 23.9904 0.238988 24.9681 0.627925C26.2718 1.55705 27.0106 2.78868 27.1518 4.29041C27.293 5.77053 26.8585 7.27226 26.174 8.60113V8.59033Z"
      fill="#F9F9F9"
    />
    <path
      d="M15.9293 6.8077L13.2785 6.68886L11.7793 6.62403L10.1606 6.55921L10.8885 5.81375L11.5729 5.12231C11.5729 5.12231 11.5077 5.05748 11.4642 5.02507C11.4534 5.01427 11.4316 4.99266 11.4208 4.98186C11.2361 4.809 11.0405 4.63613 10.8341 4.48488C8.98726 3.10199 6.22781 2.52939 3.81601 3.09119C2.66443 3.36129 1.66495 3.86906 0.861013 4.58212C1.25212 3.75022 1.76272 3.00476 2.38197 2.38894C3.89206 0.887216 6.05399 0.00130433 8.19419 0.00130433C8.23764 0.00130433 8.2811 0.00130433 8.33542 0.00130433C10.367 0.0337157 12.1921 0.638728 13.561 1.71911C13.7239 1.84875 13.876 1.9784 14.0281 2.11885C14.115 2.19448 14.202 2.28091 14.2889 2.35653L15.6903 0.930431V1.15731L15.7555 2.39975L15.9402 6.8077H15.9293Z"
      fill="#F9F9F9"
    />
    <path
      d="M4.94586 11.745C5.40215 12.2852 5.8693 12.8254 6.33645 13.344L3.53355 13.0415L3.46836 15.9369C2.99035 15.3967 2.5232 14.8565 2.05605 14.2947C0.698054 12.7498 -0.0515595 10.5566 0.00276028 8.28782C0.00276028 7.97451 0.0353522 7.672 0.0679441 7.3695C0.937061 5.18713 2.68616 4.36604 4.02243 4.05273C5.0545 3.81505 6.18435 3.81505 7.24902 4.02032C6.42336 4.20398 5.63029 4.62533 5.0545 5.19793C3.47923 6.77529 3.40318 10.0056 4.91327 11.7234L4.935 11.7558L4.94586 11.745Z"
      fill="#F9F9F9"
    />
    <path
      d="M18.2434 24.3099C17.5915 24.8825 16.9179 25.4551 16.2444 26.0061L15.0385 27L13.8326 26.0061C11.3013 23.9209 8.90035 21.6953 6.57546 19.3077C6.34731 19.0808 6.13003 18.8431 5.90189 18.6054L5.18487 19.2537L4.33748 20.0099L4.37007 18.3894L4.40267 16.9957L4.45698 14.1327L7.30334 14.446L8.75911 14.608L10.1932 14.7701L9.49786 15.3967L8.78084 16.0449C9.00898 16.2826 9.22626 16.5095 9.45441 16.7472C11.16 18.4974 12.9091 20.1396 14.7234 21.7169C14.756 21.7386 14.7777 21.7602 14.8103 21.7926L15.0385 21.987C16.1901 22.9594 17.3851 23.7805 18.2434 24.3315V24.3099Z"
      fill="#F9F9F9"
    />
    <path
      d="M24.7291 18.022C22.8714 20.0099 20.9702 21.8682 19.0038 23.64C18.1999 23.1214 16.9723 22.2895 15.7881 21.3172C17.9066 19.4698 19.9164 17.5143 21.8828 15.4075L21.8611 18.2489L24.7291 18.022Z"
      fill="#F9F9F9"
    />
    <path
      d="M27.8362 16.0125L28.5858 16.7256L27.0975 16.8444L25.696 16.9525L22.8171 17.1793L22.8388 14.3487V12.8902L22.8605 11.3021L23.6101 12.0151L24.3163 12.685C24.4141 12.5661 24.5119 12.4581 24.6205 12.3392C24.74 12.2204 24.8595 12.1016 24.979 11.9719C26.6629 10.1353 28.3794 7.14262 28.1078 4.19318C28.0426 3.53415 27.8797 2.90753 27.619 2.32412C29.0856 3.77183 29.9547 5.93259 29.9982 8.26621C30.0416 10.5566 29.3137 12.7714 27.9775 14.3704C27.9123 14.4676 27.8362 14.5432 27.771 14.6296C27.5646 14.8781 27.3474 15.1266 27.1409 15.3643L27.8471 16.0341L27.8362 16.0125Z"
      fill="#F9F9F9"
    />
  </svg>
)

export default Heart
