import React from 'react'

const Arrow = () => (
  <svg
    width="19"
    height="12"
    viewBox="0 0 19 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="7.59961"
      y="4.39992"
      width="3.80002"
      height="3.80002"
      transform="rotate(-90 7.59961 4.39992)"
      fill="currentcolor"
    />
    <rect
      x="3.7998"
      y="8.19995"
      width="3.80002"
      height="3.80002"
      transform="rotate(-90 3.7998 8.19995)"
      fill="currentcolor"
    />
    <rect
      x="11.4004"
      y="8.19995"
      width="3.80002"
      height="3.80002"
      transform="rotate(-90 11.4004 8.19995)"
      fill="currentcolor"
    />
    <rect
      y="12"
      width="3.80002"
      height="3.80002"
      transform="rotate(-90 0 12)"
      fill="currentcolor"
    />
    <rect
      x="15.2002"
      y="12"
      width="3.80002"
      height="3.80002"
      transform="rotate(-90 15.2002 12)"
      fill="currentcolor"
    />
  </svg>
)

export default Arrow
