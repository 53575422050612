import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import SiteConfig from '../types/site-config'
import IconButton from './icon-button'
import Facebook from './icons/facebook.svg'
import Instagram from './icons/instagram.svg'
import Twitter from './icons/twitter.svg'
import Youtube from './icons/youtube.svg'

const SocialIcons = ({ className, hidden = false }) => {
  const data = useStaticQuery(graphql`
    query SocialIconsQuery {
      config: prismicSiteConfig {
        ...LocalSiteConfig
      }
    }
  `)

  const config: SiteConfig = data?.config?.data
  if (!config) {
    return null
  }

  return (
    <nav className={`social-links ${className}`} aria-hidden={hidden}>
      <ul className="social-links__list">
        {config.facebook_username && (
          <li className="social-links__item">
            <IconButton
              href={`https://facebook.com/${config.facebook_username}`}
              label="Follow us on Facebook"
              target="_blank"
              rel="noopener"
            >
              <Facebook />
            </IconButton>
          </li>
        )}
        {config.instagram_username && (
          <li className="social-links__item">
            <IconButton
              href={`https://instagram.com/${config.instagram_username}`}
              label="Follow us on Instagram"
              target="_blank"
              rel="noopener"
            >
              <Instagram />
            </IconButton>
          </li>
        )}
        {config.twitter_username && (
          <li className="social-links__item">
            <IconButton
              href={`https://twitter.com/${config.twitter_username}`}
              label="Follow us on Twitter"
              target="_blank"
              rel="noopener"
            >
              <Twitter />
            </IconButton>
          </li>
        )}
        {config.youtube_username && (
          <li className="social-links__item">
            <IconButton
              href={`https://youtube.com/${config.youtube_username}`}
              label="Follow us on Youtube"
              target="_blank"
              rel="noopener"
            >
              <Youtube />
            </IconButton>
          </li>
        )}
      </ul>
    </nav>
  )
}

export default SocialIcons
