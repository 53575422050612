import { useIsInViewport } from '@superrb/gatsby-addons/hooks'
import React from 'react'
import { CarouselSectionData } from '../../types/pages/homepage'
import Globe from '../icons/globe.svg'

const CarouselSection = ({ carousel_text: text }: CarouselSectionData) => {
  const { isInViewport, setRef } = useIsInViewport(false)

  return (
    <section className="carousel-section" aria-label={text} ref={setRef}>
      <div
        className="carousel-section__inner"
        style={{ animationPlayState: isInViewport ? 'running' : 'paused' }}
      >
        {Array.from(Array(10)).map((item, i) => {
          const parts = text.split(' | ')
          return (
            <div
              className="carousel-section__item"
              key={i}
              aria-hidden={i !== 0}
            >
              {parts.map((line, index) => (
                <>
                  <span className="carousel-section__item-text">{line}</span>
                  {index < parts.length - 1 || parts.length == 1 ? (
                    <div className="carousel-section__item-icon">
                      <Globe />
                    </div>
                  ) : null}
                </>
              ))}
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default CarouselSection
