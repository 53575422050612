import { graphql } from 'gatsby'
import React from 'react'
import { Seo } from '@superrb/gatsby-addons/components'
import Homepage, {
  ShopifyProductData,
  CarouselSectionData,
  CTASectionData,
  HeroSectionData,
  InstagramSectionData,
  LargeCarouselSectionData,
  MapSectionData,
  OurStorySectionData,
  VideoSectionData,
} from '../types/pages/homepage'
import { Metadata } from '@superrb/gatsby-addons/types'
import HeroSection from '../components/homepage/hero-section'
import OurStorySection from '../components/homepage/our-story-section'
import VideoSection from '../components/homepage/video-section'
import CTASection from '../components/homepage/cta-section'
import MapSection from '../components/homepage/map-section'
import InstagramSection from '../components/homepage/instagram-section'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import LogoSection from '../components/homepage/logo-section'
import CarouselSection from '../components/homepage/carousel-section'
import LargeCarouselSection from '../components/homepage/large-carousel-section'
import loadable from '@loadable/component'
import ProductSection from '../components/homepage/product-section'

const BuyButton = loadable(() => import('../components/buy-button'))

interface Props {
  data: {
    prismicHomepage: Homepage
  }
}

const Index = ({ data }: Props) => {
  /** @type {Homepage} page */
  const page = data.prismicHomepage
  if (!page) {
    return null
  }

  return (
    <>
      <Seo data={page.data as Metadata} />

      <HeroSection {...(page.data as HeroSectionData)} />
      <CarouselSection {...(page.data as CarouselSectionData)} />
      <VideoSection {...(page.data as VideoSectionData)} />
      <LargeCarouselSection {...(page.data as LargeCarouselSectionData)} />
      <ProductSection {...(page.data as ShopifyProductData)} />
      <OurStorySection {...(page.data as OurStorySectionData)} />
      <CTASection {...(page.data as CTASectionData)} />
      <MapSection {...(page.data as MapSectionData)} />
      <CarouselSection {...(page.data as CarouselSectionData)} />
      <InstagramSection {...(page.data as InstagramSectionData)} />
      <LogoSection />
    </>
  )
}

export const query = graphql`
  query HomepageQuery($id: String) {
    prismicHomepage(id: { eq: $id }) {
      ...Homepage
    }
  }
`

export default withPrismicPreview(Index)
