import React from 'react'
import { CTASectionData } from '../../types/pages/homepage'
import GlobeMesh from '../icons/globe-mesh.svg'
import WaterText from '../icons/water-text.svg'

const CTASection = ({ cta_title: title }: CTASectionData) => {
  return (
    <section className="cta-section" aria-label={title}>
      <div className="cta-section__inner">
        <div className="cta-section__text">
          {title && (
            <h3 className="cta-section__title">
              {title.split(' ').map((item, index) => {
                return <span key={index}>{item}</span>
              })}

              <GlobeMesh />
            </h3>
          )}
        </div>

        <div className="cta-section__graphic">
          <WaterText />
        </div>
      </div>
    </section>
  )
}

export default CTASection
