import { Image } from '@superrb/gatsby-addons/components'
import React from 'react'
import { OurStorySectionData } from '../../types/pages/homepage'
import Cross from '../icons/cross.svg'
import { RichText } from '@superrb/gatsby-addons/components'
import peace from '../lottie/peace.json'
import horse from '../lottie/horse.json'
import AnimatedIcon from '../animated-icon'
import { ImageLayout } from '@superrb/gatsby-addons/types'

const OurStorySection = ({
  our_story_title: title,
  our_story_description: description,
  our_story_image: image,
  our_story_decorative_text: decorative_text,
}: OurStorySectionData) => {
  return (
    <section className="our-story-section" aria-label={title} id="about-us">
      <div className="our-story-section__inner">
        <div className="our-story-section__column">
          {image && (
            <Image
              className="our-story-section__image"
              image={image}
              layout={ImageLayout.cover}
            />
          )}
        </div>

        <div className="our-story-section__column">
          {/* {title && <h3 className="our-story-section__title">{title}</h3>} */}

          {description?.richText && (
            <div className="our-story-section__description">
              <RichText field={description.richText} />
            </div>
          )}

          {decorative_text && (
            <div className="our-story-section__decorative-text">
              <div className="our-story-section__decorative-text-icons">
                <Cross />
                <AnimatedIcon animationData={peace} />
                <AnimatedIcon animationData={horse} />
              </div>
              <div className="our-story-section__decorative-text-inner">
                {Array.from(Array(3)).map((i) => (
                  <span key={i}>{decorative_text}</span>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default OurStorySection
