import React from 'react'

const Youtube = () => (
  <svg
    width="29"
    height="21"
    viewBox="0 0 29 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_6_51"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="29"
      height="21"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H28.2052V20.3704H0V0Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_6_51)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4633 14.7211V5.05614C13.4516 6.67205 16.4213 8.27627 19.4296 9.90274C16.43 11.5141 13.4599 13.1104 10.4633 14.7211ZM27.7175 3.27761C27.3721 1.72203 26.1453 0.576 24.6681 0.40517C21.171 0.000154911 17.631 -0.00135352 14.107 0.000154919C10.5826 -0.00135352 7.04147 0.000154911 3.54403 0.40517C2.06862 0.576 0.841424 1.72203 0.497503 3.27761C0.00582299 5.49011 0 7.90624 0 10.1851C0 12.464 0 14.8812 0.492044 17.0937C0.835965 18.6478 2.0628 19.7954 3.54002 19.9662C7.0371 20.3701 10.5768 20.3727 14.1011 20.3701C17.627 20.3727 21.1666 20.3701 24.6626 19.9662C26.1395 19.7954 27.3663 18.6478 27.712 17.0937C28.2037 14.8812 28.2052 12.464 28.2052 10.1851C28.2052 7.90624 28.2095 5.49011 27.7175 3.27761Z"
        fill="currentColor"
      />
    </g>
  </svg>
)

export default Youtube
